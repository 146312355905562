import React from "react";

export default function Resume() {
  const [password, setPassword] = React.useState("");
  const [unlocked, setUnlocked] = React.useState(false);

  return (
    <div className='full-page'>
      {unlocked ? <object
        aria-label="resume "
        data="/resume.pdf"
        type="application/pdf"
        width={"100%"}
        height={"100%"}
      ></object> :
        <form onSubmit={(e) => {
          e.preventDefault();
          if (password === "please") {
            setUnlocked(true);
          }
        }
        }>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="what do we say?"
          />
          <input type="submit" style={{ display: "none" }} />
        </form>}
    </div>
  );
}
