import Nav from "../components/Nav";
import Particles from "../components/Particles";

export default function Fun() {
  return (
    <div>
      <Nav page="fun" back="true" />
      <Particles />
    </div>
  );
}
