import { useState } from "react";

import beach from "../images/vibes/beach.jpg";
import bike from "../images/vibes/bike.jpg";
import bun from "../images/vibes/bun.jpg";
import car from "../images/vibes/car.jpg";
import kiss from "../images/vibes/kiss.jpg";
import meal from "../images/vibes/meal.jpg";
import stairs from "../images/vibes/stairs.jpg";

const vibes = [beach, bike, bun, car, kiss, meal, stairs];

export function shuffle<T>(array: T[]): T[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export default function Vibes() {
  // some interesting work here to get things to be truly "random"
  // to avoid repeats

  // first attempt was to shuffle the array of vibes, but challenge is in getting that to persist
  // for each page load while we loop through the options

  // second (and current) attempt is to randomize index on render, issue is that the
  // order of vibes will be the same each time

  // too tired to work further but goal is to have
  // diff vibe each time we visit the page (do we care abt repeats?)
  // paginate through all of them with no repeats
  const [vibeIndex, setVibeIndex] = useState(
    Math.floor(Math.random() * vibes.length)
  );

  const newVibe = () => {
    setVibeIndex(
      vibeIndex === vibes.length - 1 ? 0 : vibeIndex + 1
    );
  };
  const vibe = vibes[vibeIndex];
  return (
    <img
      src={vibe}
      alt="vibe"
      className="vibe"
      onClick={() => newVibe()}
    />
  );
}
