import { useNavigate } from "react-router-dom";
import us from "../images/us.jpg";

export default function World() {
  const navigate = useNavigate();

  function returnHome() {
    navigate("/");
  }

  return (
    <div className="world" onClick={() => returnHome()}>
      <img
        src={us}
        alt={
          'screen shot of my home page with a notification from melissa in a group chat with the text "you and i see eye to eye and i love the feeling that i\'m not alone is heartwarming"'
        }
      />
      <div>
        the person you think of when you stand in front of the ocean. that's the person you're in love with.
      </div>
    </div>
  );
}
